import React, { useState } from 'react';
import _ from 'lodash';
import './App.css';
import LocationDrawer from './LocationDrawer';
import MappityMap from './MappityMap';
import locations from './locations.json';

function App() {
  const [isOpen, setIsOpen] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(false);
  const filteredLocations = _.chain(locations)
    .filter((l) => {
      const searchable = [
        l.name,
        l.address,
        l.city,
        l.state,
        l.zip,
        l.phone,
      ].join(' ').toLowerCase();
      if (_.isEqual(l, selectedLocation)) l.open = true;
      return searchable.indexOf(filterText.toLowerCase()) >= 0;
    })
    .map((l) => {
      l.open = _.isEqual(l, selectedLocation);
      return l;
    })
    .value();
  return (
    <div className="container-fluid">
      <div className="row">
        <LocationDrawer
          isOpen={isOpen}
          locations={filteredLocations}
          filterText={filterText}
          setFilterText={setFilterText}
          setSelectedLocation={setSelectedLocation}
        />
        <MappityMap
          toggleHandler={setIsOpen}
          isOpen={isOpen}
          locations={filteredLocations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
        />
      </div>
    </div>
  );
}

export default App;
