import React, { useState, useEffect } from 'react';
import _ from 'lodash';

function useMedia(queries, values, defaultValue) {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach(mql => mql.addListener(handler));
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    },// eslint-disable-next-line
    [] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
}

function LocationItem({ info, setSelectedLocation }) {
  return (
    <li className="list-group-item list-group-item-action" onClick={() => { setSelectedLocation(info) }}>
      <div className="font-weight-bold"><strong>{info.name}</strong></div>
      <div className="text-secondary small">{info.address}</div>
      <div className="text-secondary small">{info.city}, {info.state} {info.zip}</div>
      <div className="small">{info.phone}</div>
    </li>
  );
}

function LocationDrawer({ isOpen, locations, filterText, setFilterText, setSelectedLocation }) {
  
  const columnCount = useMedia(
    // Media queries
    ['(min-width: 768px)', '(max-width: 576px)'],
    // Column counts (relates to above media queries by array index)
    [2, 6],
    // Default column count
    2
  );
  if (!isOpen) return null;

  return (
    <div className={`col-${columnCount} drawer p-0 bg-dark`}>
      <form>
        <div className="form-group row p-2 mb-0">
          <div className="col">
            <div className="input-group">
              <input 
                className="form-control form-control-sm"
                type="text"
                placeholder="Filter Locations"
                onChange={(e) => setFilterText(`${e.target.value}`.toLowerCase())}
                value={filterText}
              />
              <div className="input-group-append">
                <button className="btn btn-sm btn-outline-light" type="button"><i className="fa fa-fw fa-search"></i></button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ul className="list-group list-group-flush location-list cursor">
        {_.map(locations, (l, i) => <LocationItem key={`loc-${i}`} info={l} setSelectedLocation={setSelectedLocation} />)}
      </ul>
    </div>
  );
}

export default LocationDrawer;
