import React from 'react';
import { compose, withProps } from "recompose";
import _ from 'lodash';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { MapStyles } from './MapStyles'

const API_KEY = 'AIzaSyAR5YMSfVdMrq8Yv1Vi1byVnxW7dZTQJCw';

function MarkerAndInfoWindow({ location, name, setSelectedLocation }) {
  return (
    <Marker
      key={`marker-${location.address}`}
      position={{ lat: location.lat, lng: location.long }}
      onClick={() => { setSelectedLocation(location) }}
    >
      {
        (location.name === name)
        ? <InfoWindow onCloseClick={() => { setSelectedLocation(false) }}>
          <div className="pt-3 pl-3">
            <p>{location.name}</p>
            <p>
              {location.address}<br />
              {location.city}, {location.state} {location.zip}
            </p>
            <hr className="bg-dark" />
            <p>{location.unit_count} units - {location.unit_type}</p>
          </div>
        </InfoWindow>
        : null
      }
    </Marker>
  );
}

const MappityMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="col p-0 bg-secondary" style={{ height: '500px' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(({ toggleHandler, isOpen, locations, setSelectedLocation, selectedLocation }) => {
  let lat = 40.5743177, lng = -122.4327701;
  if (selectedLocation) {
    lat = selectedLocation.lat;
    lng = selectedLocation.long;
  }
  return (
    <GoogleMap
      center={{ lat, lng }}
      defaultZoom={5}
      defaultCenter={{ lat, lng }}
      defaultOptions={{ styles: MapStyles }}
    >
      <button
        className="btn btn-sm btn-dark"
        id="drawer-toggle"
        onClick={() => { toggleHandler(!isOpen) }}
      ><strong>{ (isOpen) ? '<' : '>' }</strong></button>
      {_.map(locations, (l, i) => <MarkerAndInfoWindow key={`miw-${i}`} location={l} name={_.get(selectedLocation, 'name', '')} setSelectedLocation={setSelectedLocation} /> )}
    </GoogleMap>
  );
});

export default MappityMap;
